@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&family=Varela+Round&display=swap");
* {
  box-sizing: border-box;
}

/* body {
  background: #f3f1f1;
} */

body {
  margin: 0;
  font-family: "Varela Round", "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f6f9;
}

.main-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  padding: 0.5rem 1rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  height: 6rem;
}

.main-header__brand {
  font-size: 1.5rem;
}

.main-header__brand img {
  height: 3rem;
  margin-left: 3rem;
}

.main-navbar__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

.main-navbar__item {
  margin: 0 1rem;
}

.main-navbar__item a {
  text-decoration: none;
  color: black;
  padding: 3px 0;
}

.main-navbar__item a:hover,
.main-navbar__item a:active {
  border-bottom: 5px solid black;
}

.btn-primary {
  background: #4fd1a6;
  color: #0f1825;
  font: inherit;
  border: 1.5px solid #4fd1a6;
  padding: 1rem 2rem;
  font-weight: bold;
  cursor: pointer;
}

.btn-primary:hover,
.btn-primary:active {
  background: #00c483;
}

.btn-primary[disabled] {
  color: grey;
  border: black;
  background: white;
  border: 1.5px solid #4fd1a6;
  cursor: not-allowed;
}

.btn-secondary {
  background: #4fd1a6;
  color: #0f1825;
  font: inherit;
  border: 1.5px solid #4fd1a6;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.btn-secondary:hover,
.btn-secondary:active {
  background: #00c483;
}

.btn-secondary[disabled] {
  color: grey;
  border: black;
  background: white;
  border: 1.5px solid #4fd1a6;
  cursor: not-allowed;
}

/* .main-flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.homepage-flex-container {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage-flex-container .btn-primary {
  width: 10rem;
  margin: 1rem;
}

.dashboard-main-container {
  margin: 5rem;
  margin-top: 7rem;
}

.form-div {
  margin: 5%;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-bar-links {
  display: flex;
  justify-content: space-evenly;
}

.dashboard-div {
  display: flex;
  flex-direction: column;
}

.create-advance-button {
  margin: 1rem auto;
}

/* .dashboard-table-headings {
  display: flex;
  justify-content: space-between;
} */

/* .dashboard-table {
  display: flex;
  flex-direction: column;
} */

/* .table-headings-container {
  display: flex;
  justify-content: space-evenly;
} */

/* .table-values {
  display: flex;
  justify-content: space-around;
} */

.main-flex-container {
  background: #0f1825;
}

.homepage-flex-container,
.profile-container,
.generic-container {
  background: white;
  max-width: 50%;
  margin: 10rem auto;
  border: 1px solid #0f1825;
  border-radius: 20px;
  padding: 3rem;
}

/* .MuiInputLabel-root {
  font-size: 1.5rem !important;
  padding-bottom: 50px !important;
}

.MuiInput-root {
  height: 45px !important;
} */

/* input {
  padding: 0.5rem !important;
} */

.dashboard-main-container {
  background: white;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 20px;
}

/* .auth-form-container {
  margin: 10rem auto;
  display: grid;
  max-width: 35rem;
  row-gap: 0.6rem;
} */

/* .auth-form-container select {
  font-family: inherit;
}

.auth-form-container label {
  font-weight: 500;
}

.auth-form-container h2 {
  justify-self: center;
} */

.form-error-text {
  color: red;
  font-weight: 500;
}

.form-success-text {
  color: green;
  font-weight: 500;
}

.auth-form-password-widget {
  text-decoration: underline;
}

.profile-container {
  margin: 10rem auto;
  max-width: 35rem;
  display: grid;
}

.profile-container .profile-title {
  font-weight: 500;
}

.dashboard-table {
  margin-top: 2rem;
}

.table-headings-values {
  display: grid;
  grid-template-columns: repeat(8, auto);
  column-gap: 1rem;
  row-gap: 2rem;
}

.table-headings-values .table-heading {
  text-align: center;
  font-weight: 600;
  padding: 0.5rem;
  background-color: #00c483;
}

.table-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-headings-values div {
  text-align: center;
}

.generic-container {
  margin: 10rem auto;
  max-width: 35rem;
}

.generic-container h2 {
  text-align: center;
}

.id-verification-container {
  display: grid;
  row-gap: 2rem;
}

.two-button-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: center;
}

.address-verification-form {
  display: grid;
  row-gap: 1rem;
}

.address-verification-form-address {
  display: grid;
  row-gap: 0.5rem;
}

.new-advance-conf-tickbox {
  margin-top: 1rem;
}

.full-container-width-button button {
  width: 100%;
}

.PhoneInputInput {
  /* background: #00c483; */
  height: 2rem;
  border-color: white;
}

p#my-p-tag {
  /* Reset all inherited styles. */
  all: unset;
}

.files-ui-footer {
  font-family: "Varela Round", "Montserrat", sans-serif !important;
  background-color: #4fd1a6 !important;
  color: #0f1825 !important;
  font-weight: 600 !important;
  padding: 10px !important;
}

.italic {
  font-style: italic;
}
